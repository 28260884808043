#floatingBarsG{
	position:relative;
	width:60px;
	height:75px;
	margin:auto;
}

.blockG{
	position:absolute;
	background-color:rgb(255,255,255);
	width:10px;
	height:23px;
	border-radius:8px 8px 0 0;
		-o-border-radius:8px 8px 0 0;
		-ms-border-radius:8px 8px 0 0;
		-webkit-border-radius:8px 8px 0 0;
		-moz-border-radius:8px 8px 0 0;
	transform:scale(0.4);
		-o-transform:scale(0.4);
		-ms-transform:scale(0.4);
		-webkit-transform:scale(0.4);
		-moz-transform:scale(0.4);
	animation-name:fadeG;
		-o-animation-name:fadeG;
		-ms-animation-name:fadeG;
		-webkit-animation-name:fadeG;
		-moz-animation-name:fadeG;
	animation-duration:0.832s;
		-o-animation-duration:0.832s;
		-ms-animation-duration:0.832s;
		-webkit-animation-duration:0.832s;
		-moz-animation-duration:0.832s;
	animation-iteration-count:infinite;
		-o-animation-iteration-count:infinite;
		-ms-animation-iteration-count:infinite;
		-webkit-animation-iteration-count:infinite;
		-moz-animation-iteration-count:infinite;
	animation-direction:normal;
		-o-animation-direction:normal;
		-ms-animation-direction:normal;
		-webkit-animation-direction:normal;
		-moz-animation-direction:normal;
}

#rotateG_01{
	left:0;
	top:27px;
	animation-delay:0.3095s;
		-o-animation-delay:0.3095s;
		-ms-animation-delay:0.3095s;
		-webkit-animation-delay:0.3095s;
		-moz-animation-delay:0.3095s;
	transform:rotate(-90deg);
		-o-transform:rotate(-90deg);
		-ms-transform:rotate(-90deg);
		-webkit-transform:rotate(-90deg);
		-moz-transform:rotate(-90deg);
}

#rotateG_02{
	left:8px;
	top:10px;
	animation-delay:0.416s;
		-o-animation-delay:0.416s;
		-ms-animation-delay:0.416s;
		-webkit-animation-delay:0.416s;
		-moz-animation-delay:0.416s;
	transform:rotate(-45deg);
		-o-transform:rotate(-45deg);
		-ms-transform:rotate(-45deg);
		-webkit-transform:rotate(-45deg);
		-moz-transform:rotate(-45deg);
}

#rotateG_03{
	left:25px;
	top:3px;
	animation-delay:0.5225s;
		-o-animation-delay:0.5225s;
		-ms-animation-delay:0.5225s;
		-webkit-animation-delay:0.5225s;
		-moz-animation-delay:0.5225s;
	transform:rotate(0deg);
		-o-transform:rotate(0deg);
		-ms-transform:rotate(0deg);
		-webkit-transform:rotate(0deg);
		-moz-transform:rotate(0deg);
}

#rotateG_04{
	right:8px;
	top:10px;
	animation-delay:0.619s;
		-o-animation-delay:0.619s;
		-ms-animation-delay:0.619s;
		-webkit-animation-delay:0.619s;
		-moz-animation-delay:0.619s;
	transform:rotate(45deg);
		-o-transform:rotate(45deg);
		-ms-transform:rotate(45deg);
		-webkit-transform:rotate(45deg);
		-moz-transform:rotate(45deg);
}

#rotateG_05{
	right:0;
	top:27px;
	animation-delay:0.7255s;
		-o-animation-delay:0.7255s;
		-ms-animation-delay:0.7255s;
		-webkit-animation-delay:0.7255s;
		-moz-animation-delay:0.7255s;
	transform:rotate(90deg);
		-o-transform:rotate(90deg);
		-ms-transform:rotate(90deg);
		-webkit-transform:rotate(90deg);
		-moz-transform:rotate(90deg);
}

#rotateG_06{
	right:8px;
	bottom:7px;
	animation-delay:0.832s;
		-o-animation-delay:0.832s;
		-ms-animation-delay:0.832s;
		-webkit-animation-delay:0.832s;
		-moz-animation-delay:0.832s;
	transform:rotate(135deg);
		-o-transform:rotate(135deg);
		-ms-transform:rotate(135deg);
		-webkit-transform:rotate(135deg);
		-moz-transform:rotate(135deg);
}

#rotateG_07{
	bottom:0;
	left:25px;
	animation-delay:0.9385s;
		-o-animation-delay:0.9385s;
		-ms-animation-delay:0.9385s;
		-webkit-animation-delay:0.9385s;
		-moz-animation-delay:0.9385s;
	transform:rotate(180deg);
		-o-transform:rotate(180deg);
		-ms-transform:rotate(180deg);
		-webkit-transform:rotate(180deg);
		-moz-transform:rotate(180deg);
}

#rotateG_08{
	left:8px;
	bottom:7px;
	animation-delay:1.035s;
		-o-animation-delay:1.035s;
		-ms-animation-delay:1.035s;
		-webkit-animation-delay:1.035s;
		-moz-animation-delay:1.035s;
	transform:rotate(-135deg);
		-o-transform:rotate(-135deg);
		-ms-transform:rotate(-135deg);
		-webkit-transform:rotate(-135deg);
		-moz-transform:rotate(-135deg);
}



@keyframes fadeG{
	0%{
		background-color:#FF4D00;
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-o-keyframes fadeG{
	0%{
		background-color:#FF4D00;
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-ms-keyframes fadeG{
	0%{
		background-color:#FF4D00;
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-webkit-keyframes fadeG{
	0%{
		background-color:#FF4D00;
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-moz-keyframes fadeG{
	0%{
		background-color:#FF4D00;
	}

	100%{
		background-color:rgb(255,255,255);
	}
}