body {
    font-family: 'IBM Plex Sans Arabic';
}
@mixin fontStyle($size, $lineHeight, $weight) {
    font-size: $size ;
    line-height: $lineHeight;
    font-weight: $weight;
}
@mixin md-screen {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin lg-screen {
    @media only screen and (min-width: 992px) {
        @content;
    }
}

@mixin tabletOnly {
    @media only screen and (min-width: 767px) and (max-width: 1023px) {
        @content;
    }
}