@import "../global.scss";
@import "../typography/typo";

.heart-icon{
    border: 0;
    background: none;
}
.fav {
    path{
        fill: #FF4D00;
    }
}