@import "typography/_typo.scss";

.container {
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
}
.text-left{
    text-align: left;
}
p{
    margin-bottom: 0;
}
.menu-wrapper {
    background: #FAFAFA;
    padding-top: 25px;
    padding-bottom: 25px;
}

.mt-20{
    margin-top: 20px;
}
.product-hero-section {
    @include md-screen {
        padding-top: 115px;
        padding-bottom: 115px;
    }

    padding-top: 55px;
    padding-bottom: 55px;

}

.product-title {
    max-width: 450px;
    @include fontStyle(30px, 40px, 500);
    letter-spacing: 0.02em;
    color: #000839;
}

.old-price {
    @include fontStyle(20px, 34px, 400);
    letter-spacing: -0.02em;
    text-decoration-line: line-through;

    color: #000000;

    opacity: 0.5;
}

.new-price {
    @include fontStyle(24px, 34px, 400);
    letter-spacing: -0.02em;
    padding-left: 8px;
    color: #000000;
}

.creator {
    @include fontStyle(14px, 22px, 500);
    letter-spacing: 0.02em;
    color: #637596;
}

.review-number,
.answered-questions {
    @include fontStyle(14px, 22px, 500);
    letter-spacing: 0.02em;
    padding-left: 12px;
    color: #000839;
}

.review-list {
    list-style: none;
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    @include md-screen {
        margin-top: 0;
    }




}

.review-list::before {
    margin-right: 15px;
    content: " ";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #D3D8DF;
}

.product-information {
    border-radius: 20px;
    padding: 40px;
    border: 1px solid #C4D0E1;
}

.payment-info {
    margin-top: 20px;
    border-top: 1px solid #C8D3E3;
}

.payment-method {
    padding-top: 20px;
    margin-bottom: 15px;
    @include fontStyle(14px, 22px, 500);
    letter-spacing: 0.02em;

    color: #000839;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mt-10 {
    margin-top: 10px;

}

.payment {
    background: #f7f7f7;
    padding: 20px 32px;
    border-radius: 12px;
    flex-wrap: wrap;

    &+.payment {
        margin-top: 15px;
    }

    .payment-desc {
        @include fontStyle(11px, 26px, 500);
        padding-left: 20px;
        letter-spacing: 0.02em;
        color: #000000;
    }

    .ex-date {
        flex: 0 0 100%;
        @include fontStyle(13px, 20px, 500);
        margin-top: 6px;
        padding-left: 0;
        /* identical to box height */

        color: #1E1F20;

        @include md-screen {
            padding-left: 50px;
        }
    }
}

.buttons-wrapper {
    margin-bottom: 32px;

    .quantatiy-number {
        margin-bottom: 0;
    }

    .increase-number {
        background: #FFFFFF;
        border: 1px solid #C4D0E1;
        padding: 14px 25px;
        border-radius: 50px;
        @include fontStyle(24px, 32px, 500);
        min-width: 255px;
        width: 100%;
        letter-spacing: 0.02em;
        color: #000839;

        @include md-screen {

            width: auto;
        }
    }

  

    margin-top: 35px;

    button {
        background: none;
        border: none;

    }
}

.radio-container {
    display: inline-block;
    position: relative;
    padding-left: 50px;
    cursor: pointer;
    @include fontStyle(15px, 26px, 500);

    letter-spacing: 0.02em;

    color: #000000;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 21px;
    width: 21px;
    border: 1px solid #9393AA;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */


/* When the radio button is checked, add a blue background */
.radio-container input:checked~.checkmark {
    background-color: transparent;

}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
    top: 4px;
    left: 4px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #FF4D00;
}





.available-text {
    @include fontStyle(1.2rem,1.2rem,700);

}
.light-text{
padding-top: 18px;
@include fontStyle(1.3rem,1.3rem,400);
color: #7581A1;
display: inline-block;
}

.custom-box-shadow{
    box-shadow: 0px 0px 52.5px rgba(0, 0, 0, 0.101961);
border-radius: 16px;
}
a{
    text-decoration: none;
}
/**********Start Slick***********/
.slick-wrapper {
    margin-bottom: 32px;

    .slick-dots button {
        width: 8px;
        height: 8px;
        margin-left: 5px;
        margin-right: 5px;
        padding:4px;
        background-color: #C2CAD6;
        border-radius: 50%;
        text-indent: -99999px;
        overflow: hidden;
    }

    .slick-dots {
        display: flex;
        justify-content: center;
        list-style: none;
        margin-bottom: 0;
        margin-top: 40px;
        padding-left: 0;
    }

    .slick-dots li {
      
        all: unset !important;
        position: relative !important;
        display: inline-block !important;
    }

    .slick-dots li:first-child {
        margin-left: 0;
    }

    .slick-dots .slick-active button {
        background-color: #FF4D00;
    }
}
.pr-20{
    padding-right: 20px;
}
.mb-20{
    margin-bottom: 20px;
}

  .PhoneInput{
      position: relative !important;
      .PhoneInputCountry {
        position: absolute ;
        top: 18px;
        left: 22px;
      }
  }