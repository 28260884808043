@import "../global.scss";
@import "../typography/typo";
  .buy-now,.buy-now:hover {

    background: #FF4D00 !important;
    border-radius: 50px;
    @include fontStyle(16px, 28px, 600);
    margin-left: 0px;
    min-width: 255px;
    /* identical to box height, or 175% */
    letter-spacing: 0.02em;
    padding: 16px 30px;
    border: none;
    color: #FFFFFF;
    margin-top: 24px;
    width: 100%;

    @include md-screen {
        margin-left: 10px;
        margin-top: 0;
        width: auto;
    }
}