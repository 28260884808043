@import "../typography/typo";

.msgtitle{
    @include fontStyle(30px,44px,700);
    margin-top: 30px;
}
.msgnote{
    color: #959595;
    @include fontStyle(17px,26px,400);
margin-top: 14px;
}