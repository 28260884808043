
@import "../typography/typo";

.otp-container{
    margin-top: 50px;
    padding: 50px 24px;
    @include lg-screen{
        margin-top: 150px;
        padding:88px 161px 141px 161px ;
    }
}
.enter-number{
@include fontStyle(17px,22px,500);
margin-top: 88px;
margin-bottom: 29px;
}
.confirm,.confirm:hover{
    margin-top: 24px;
    background: #FF4D00;
    color: #fff;
    border-radius: 12px;
    padding: 6% 30%;
    display: inline-block;
    border: 0;
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
@include fontStyle(14px,21px,700);
}
.confirm[disabled]{
    opacity: 0.5;
    cursor: no-drop;
}
.react-code-input {
    width: 100% !important;
}
.react-code-input{
    input{
        padding: 5% !important;
        width: 20% !important;
        height: 76px !important;
        border: 1px solid #E8E8E8;
        font-size: 17px !important;
        line-height: 26px !important;
        font-weight: 400 !important;
        border-radius: 12px;
        text-align: center;
        /* Chrome, Safari, Edge, Opera */
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.timer{
    margin-top: 22px;
    margin-bottom: 15px;
    @include fontStyle(14px,17px,400);
}
.resend-button{
    background:none;
    color: #FF4D00;
    border: 0;
    
}