@import "../typography/typo";
.p-relative{
    position: relative;
}
.process-request{
    font-weight: 700;
font-size: 29px;
line-height: 44px;
@include fontStyle(29px,44px,700);

padding-top: 70px;
padding-bottom: 45px;
text-align: center;

color: #000000;


}
.Proceeing {
    @include fontStyle(17px,26px,400);
    color: #2D2D2D;
    margin-top: 40px;
  }
  .big-loader{
      z-index: 9999;
      top: 0;
      
      background-color: #fff;
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      #floatingBarsG{
          top: 48%;
      }
  }