@import "../global.scss";
@import "../typography/typo";

.checkout-wrapper{
    box-shadow: 0px 0px 52.5px rgba(0, 0, 0, 0.101961);
border-radius: 16px;
padding-top: 100px;
padding-bottom: 100px;
}
.store-wrap{
    @include fontStyle(17px,22px,400);
    margin-top: 40px;
    margin-bottom: 40px;
}
.store-name{
    @include fontStyle(21px,22px,700);

    color: #FF4D00;
}
.merchant-logo{
    max-width: 150px;
    margin: 45px auto 0 auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
}
.available-balance {
box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
border-radius: 12px;
padding-top: 15px;
padding: 15px 36px 24px 36px;
padding-bottom: 24px;
}
.available-amount {
color: #FF4D00;
@include fontStyle(1.7rem,1.7rem,800);
padding-bottom: 13px;
}

@media only screen and (min-width: 1024px) {
    .mw-lg-60 {
        max-width: 60% !important;
    }
}
