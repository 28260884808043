
@import "../typography/typo";

.phone-number-container{
    margin-top: 50px;
    padding: 50px 24px;
    @include lg-screen{
        margin-top: 150px;
        padding:88px 161px 141px 161px ;
    }
}
.enter-number{
@include fontStyle(17px,22px,500);
margin-top: 88px;
margin-bottom: 29px;
}
.Proceed,.Proceed:hover{
    margin-top: 24px;
    background: #FF4D00;
border-radius: 12px;
padding: 15px 137px;
color: #fff;
display: block;
@include fontStyle(14px,21px,700);
}
.Proceed[disabled] {
    opacity: .5;
    cursor: no-drop;
  }
.phone-number-container{
    input{
        padding: 12px 24px;
        border: 1px solid #E8E8E8;
border-radius: 8px;
display: block;
width: 100%;
margin-bottom: 22px;

    }
    .PhoneInputInput{
        padding-left: 68px !important;
    }
    select{
        display: block;
        width: 100%;
        padding: 12px 16px;
        background: transparent;
        border: 1px solid #E8E8E8;
        border-radius: 8px;
        margin-bottom: 22px; 
    }
    .PhoneInputCountrySelect {
        display: block;
        width: 100%;
     
        background: transparent;
        border: 1px solid #E8E8E8;
        margin-bottom: 0;
             
    }
  
}