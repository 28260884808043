@import "../global.scss";
@import "../typography/typo";
.order-detail-wrapper{
    padding-top: 40px;
    padding-bottom: 40px;
.order-detail{
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='33' ry='33' stroke='%23DBDBDBFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e");
    padding: 28px 32px 45px 32px;
}
.order-name{
    @include fontStyle(18px,30px,500);
    margin-bottom: 40px;
    text-align: center;
}
.order-id{
@include fontStyle(18px,34px,400);
letter-spacing: -0.02em;

color: #707070;
}
.order-product-name{
    letter-spacing: 0.02em;
    padding-top: 3px;
    padding-bottom: 3px;
    @include fontStyle(18px,32px,500);
}
.quantity{
    letter-spacing: -0.02em;
    @include fontStyle(18px,34px,400);
    
   color: #707070;
}
.discount{
    letter-spacing: -0.02em;
    @include fontStyle(18px,34px,400);
color: #F04461;
}
.price-details-wrapper{
    padding-top: 15px;
    border-top: 1px solid #E1E1E1;
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 15px;
}
.price-detail{
    @include fontStyle(19px,34px,500);
    letter-spacing: -0.02em;
}
}
.total-price-text{
    @include fontStyle(19px,34px,700);
    letter-spacing: -0.02em;
}
.total-price-amount{
    @include fontStyle(27px,34px,700);
    letter-spacing: -0.02em;
}
.confirmation-bottom,.confirmation-bottom:hover{
    background: #FF4D00;
border-radius: 12px;
padding-top: 12px;
padding-bottom: 12px;
border: 0;
display: block;
color: #fff;
text-align: center;
@include fontStyle(14px,21px,700);
color: #fff;
width: 100%;
margin-top: 40px;
}
.mb-40{
    margin-bottom: 40px;
}
.value{
@include fontStyle(20px,34px,400);
letter-spacing: -0.02em;


}